import {
  APP_ACTIONS
} from '../actions/app';

const defaultState = {
  theme: 'dark',
  dialog: {
    open: false,
    title: "",
    content: []
  }
}

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case APP_ACTIONS.SET_THEME:
      return {
        ...state,
        theme: action.theme
      };
    case APP_ACTIONS.OPEN_DIALOG:
      return {
        ...state,
        dialog: action.dialog
      };
    case APP_ACTIONS.CLOSE_DIALOG:
      return {
        ...state,
        dialog: {
          open: false
        }
      };
    default:
      return state;
  }
}

export default appReducer;