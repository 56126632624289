export const APP_ACTIONS = {
  SET_THEME: "SET_THEME",

  // Dialog
  OPEN_DIALOG: "OPEN_DIALOG",
  CLOSE_DIALOG: "CLOSE_DIALOG"
}

export const setTheme = (theme) => ({
  type: APP_ACTIONS.SWITCH_THEME,
  theme
});

export const openDialog = (data) => ({
  type: APP_ACTIONS.OPEN_DIALOG,
  dialog: {
    open: true,
    ...data
  }
})

export const closeDialog = () => ({
  type: APP_ACTIONS.CLOSE_DIALOG
})