import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

export default function EmailSnackbar() {
  var content = "E-Mail copied successfully"
  const [open, setOpen] = React.useState(false);

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
  };

  const  copyEmail = async (e) => {
      const type = 'text/plain';
      const blob = new Blob(["support@ogafit.com"], { type });
      // eslint-disable-next-line no-undef
        let data = [new ClipboardItem({ [type]: blob })];
        navigator.clipboard.write(data).then(function() {
          content = "E-Mail copied successfully"
          setOpen(true)
          setInterval(() => {
            setOpen(false)
          }, 1000);
        }, function() {
          content = "Sorry, an error occurred while copying the text. Please contact Oga fit staff team!"
          setOpen(true)
          setInterval(() => {
            setOpen(false)
          }, 1000);
      });
  }
  return (
    <div>  
      <p>
         Or use <span
          onClick={copyEmail} class="altEmail">support@ogafit.com
        </span> (click to copy)
       </p>
      <Snackbar
        open={open}
        onClose={handleClose}
        message={content}
      />
    </div>
  );
}