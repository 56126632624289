import {
  USER_ACTIONS
} from '../actions/user'

const initialState = {
  fullname: "",
  first: "Alex",
  last: "van den Oudenhoven",
  email: 'a.oudenhoven@ogafit.com',
  gender: "male",
  identifier: "0000",
  username: "Lexouden",
  dob: '2000-10-25'
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS.SET_USER:
      return {
        ...state,
        ...action.user
      };
    default:
      return state;
  }
}

export default userReducer;